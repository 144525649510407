import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import template from "src/shared/notification/notification-flash-template.html?raw";

const NOTIFICATION_WRAPPER_CLASS_NAME = "notification-flash-wrapper";
const COVER_CLASS_NAME = "notification-flash-cover";
const NotificationFlashView = Backbone.View.extend({
    template: _.template(template),

    initialize(parameters) {
        this.message = parameters.message;
        this.withoutWrapper = parameters.withoutWrapper;
        this.$coverElement = parameters.$coverElement || $();
    },

    render() {
        this.$el.html(
            this.template({
                message: this.message || "Loading",
            })
        );
        if (!this.withoutWrapper) {
            this.$el.addClass(NOTIFICATION_WRAPPER_CLASS_NAME);
        }

        return this;
    },

    show() {
        if (!this.withoutWrapper) {
            this.$el.addClass(NOTIFICATION_WRAPPER_CLASS_NAME);
        }
        this.$el.show();
        this.$coverElement.addClass(COVER_CLASS_NAME);
    },

    fadeIn() {
        if (!this.withoutWrapper) {
            this.$el.addClass(NOTIFICATION_WRAPPER_CLASS_NAME);
        }
        this.$el.fadeIn();
        this.$coverElement.addClass(COVER_CLASS_NAME);
    },

    hide() {
        this.$el.removeClass(NOTIFICATION_WRAPPER_CLASS_NAME).hide();
        this.$coverElement.removeClass(COVER_CLASS_NAME);
    },
});

NotificationFlashView.createInside = function (
    $el,
    message,
    $coverElement,
    withoutWrapper
) {
    const $div = $("<div />");
    $el.empty().append($div);
    return new NotificationFlashView({
        el: $div,
        message,
        withoutWrapper,
        $coverElement,
    }).render();
};

NotificationFlashView.create = function (
    $el,
    message,
    $coverElement,
    withoutWrapper
) {
    return new NotificationFlashView({
        el: $el,
        message,
        withoutWrapper,
        $coverElement,
    }).render();
};

NotificationFlashView.renderAll = function (
    $selectors,
    message,
    withoutWrapper
) {
    $selectors.each(function () {
        new NotificationFlashView({
            el: $(this),
            message,
            withoutWrapper,
        }).render();
    });
};

export default NotificationFlashView;
