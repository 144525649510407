import Foundation from "./foundation";
import jQuery from "jquery";

(function ($, window, document) {
    "use strict";

    Foundation.libs.dropdown = {
        name: "dropdown",

        version: "5.4.5",

        settings: {
            active_class: "open",
            mega_class: "mega",
            align: "bottom",
            is_hover: false,
            opened: function () {},
            closed: function () {},
        },

        init: function (scope, method, options) {
            Foundation.inherit(this, "throttle");

            this.bindings(method, options);
        },

        events: function (scope) {
            const self = this,
                { S } = self;

            S(this.scope)
                .off(".dropdown")
                .on(
                    "click.fndtn.dropdown",
                    "[" + this.attr_name() + "]",
                    function (e) {
                        const settings =
                            S(this).data(self.attr_name(true) + "-init") ||
                            self.settings;
                        if (!settings.is_hover || Modernizr.touch) {
                            e.preventDefault();
                            self.toggle($(this));
                        }
                    }
                )
                .on(
                    "mouseenter.fndtn.dropdown",
                    "[" +
                        this.attr_name() +
                        "], [" +
                        this.attr_name() +
                        "-content]",
                    function (e) {
                        let $this = S(this),
                            dropdown,
                            target;

                        clearTimeout(self.timeout);

                        if ($this.data(self.data_attr())) {
                            dropdown = S("#" + $this.data(self.data_attr()));
                            target = $this;
                        } else {
                            dropdown = $this;
                            target = S(
                                "[" +
                                    self.attr_name() +
                                    "='" +
                                    dropdown.attr("id") +
                                    "']"
                            );
                        }

                        const settings =
                            target.data(self.attr_name(true) + "-init") ||
                            self.settings;

                        if (
                            S(e.target).data(self.data_attr()) &&
                            settings.is_hover
                        ) {
                            self.closeall.call(self);
                        }

                        if (settings.is_hover)
                            self.open.apply(self, [dropdown, target]);
                    }
                )
                .on(
                    "mouseleave.fndtn.dropdown",
                    "[" +
                        this.attr_name() +
                        "], [" +
                        this.attr_name() +
                        "-content]",
                    function (e) {
                        const $this = S(this);
                        self.timeout = setTimeout(
                            function () {
                                if ($this.data(self.data_attr())) {
                                    var settings =
                                        $this.data(
                                            self.data_attr(true) + "-init"
                                        ) || self.settings;
                                    if (settings.is_hover)
                                        self.close.call(
                                            self,
                                            S(
                                                "#" +
                                                    $this.data(self.data_attr())
                                            )
                                        );
                                } else {
                                    var target = S(
                                            "[" +
                                                self.attr_name() +
                                                '="' +
                                                S(this).attr("id") +
                                                '"]'
                                        ),
                                        settings =
                                            target.data(
                                                self.attr_name(true) + "-init"
                                            ) || self.settings;
                                    if (settings.is_hover)
                                        self.close.call(self, $this);
                                }
                            }.bind(this),
                            150
                        );
                    }
                )
                .on("click.fndtn.dropdown", function (e) {
                    const parent = S(e.target).closest(
                        "[" + self.attr_name() + "-content]"
                    );

                    if (
                        S(e.target).closest("[" + self.attr_name() + "]")
                            .length > 0
                    ) {
                        return;
                    }
                    if (
                        !S(e.target).data("revealId") &&
                        parent.length > 0 &&
                        (S(e.target).is("[" + self.attr_name() + "-content]") ||
                            $.contains(parent.first()[0], e.target))
                    ) {
                        e.stopPropagation();
                        return;
                    }

                    self.close.call(
                        self,
                        S("[" + self.attr_name() + "-content]")
                    );
                })
                .on(
                    "opened.fndtn.dropdown",
                    "[" + self.attr_name() + "-content]",
                    function () {
                        self.settings.opened.call(this);
                    }
                )
                .on(
                    "closed.fndtn.dropdown",
                    "[" + self.attr_name() + "-content]",
                    function () {
                        self.settings.closed.call(this);
                    }
                );

            S(window)
                .off(".dropdown")
                .on(
                    "resize.fndtn.dropdown",
                    self.throttle(function () {
                        self.resize.call(self);
                    }, 50)
                );

            this.resize();
        },

        close: function (dropdown) {
            const self = this;
            dropdown.each(function () {
                const original_target =
                    $("[" + self.attr_name() + "=" + dropdown[0].id + "]") ||
                    $("aria-controls=" + dropdown[0].id + "]");
                original_target.attr("aria-expanded", "false");
                if (self.S(this).hasClass(self.settings.active_class)) {
                    self.S(this)
                        .css(Foundation.rtl ? "right" : "left", "-99999px")
                        .attr("aria-hidden", "true")
                        .removeClass(self.settings.active_class)
                        .prev("[" + self.attr_name() + "]")
                        .removeClass(self.settings.active_class)
                        .removeData("target");

                    self.S(this)
                        .trigger("closed")
                        .trigger("closed.fndtn.dropdown", [dropdown]);
                }
            });
        },

        closeall: function () {
            const self = this;
            $.each(self.S("[" + this.attr_name() + "-content]"), function () {
                self.close.call(self, self.S(this));
            });
        },

        open: function (dropdown, target) {
            this.css(dropdown.addClass(this.settings.active_class), target);
            dropdown
                .prev("[" + this.attr_name() + "]")
                .addClass(this.settings.active_class);
            dropdown
                .data("target", target.get(0))
                .trigger("opened")
                .trigger("opened.fndtn.dropdown", [dropdown, target]);
            dropdown.attr("aria-hidden", "false");
            target.attr("aria-expanded", "true");
            dropdown.focus();
        },

        data_attr: function () {
            if (this.namespace.length > 0) {
                return this.namespace + "-" + this.name;
            }

            return this.name;
        },

        toggle: function (target) {
            const dropdown = this.S("#" + target.data(this.data_attr()));
            if (dropdown.length === 0) {
                // No dropdown found, not continuing
                return;
            }

            this.close.call(
                this,
                this.S("[" + this.attr_name() + "-content]").not(dropdown)
            );

            if (dropdown.hasClass(this.settings.active_class)) {
                this.close.call(this, dropdown);
                if (dropdown.data("target") !== target.get(0))
                    this.open.call(this, dropdown, target);
            } else {
                this.open.call(this, dropdown, target);
            }
        },

        resize: function () {
            const dropdown = this.S("[" + this.attr_name() + "-content].open"),
                target = this.S(
                    "[" + this.attr_name() + "='" + dropdown.attr("id") + "']"
                );

            if (dropdown.length && target.length) {
                this.css(dropdown, target);
            }
        },

        css: function (dropdown, target) {
            const left_offset = Math.max(
                    (target.width() - dropdown.width()) / 2,
                    8
                ),
                settings =
                    target.data(this.attr_name(true) + "-init") ||
                    this.settings;

            this.clear_idx();

            if (this.small()) {
                const p = this.dirs.bottom.call(dropdown, target, settings);

                dropdown
                    .attr("style", "")
                    .removeClass("drop-left drop-right drop-top")
                    .css({
                        position: "absolute",
                        width: "95%",
                        "max-width": "none",
                        top: p.top,
                    });

                dropdown.css(Foundation.rtl ? "right" : "left", left_offset);
            } else {
                this.style(dropdown, target, settings);
            }

            return dropdown;
        },

        style: function (dropdown, target, settings) {
            const css = $.extend(
                { position: "absolute" },
                this.dirs[settings.align].call(dropdown, target, settings)
            );

            dropdown.attr("style", "").css(css);
        },

        // return CSS property object
        // `this` is the dropdown
        dirs: {
            // Calculate target offset
            _base: function (t) {
                const o_p = this.offsetParent(),
                    o = o_p.offset(),
                    p = t.offset();

                p.top -= o.top;
                p.left -= o.left;

                return p;
            },
            top: function (t, s) {
                const self = Foundation.libs.dropdown,
                    p = self.dirs._base.call(this, t);

                this.addClass("drop-top");

                if (
                    t.outerWidth() < this.outerWidth() ||
                    self.small() ||
                    this.hasClass(s.mega_menu)
                ) {
                    self.adjust_pip(this, t, s, p);
                }

                if (Foundation.rtl) {
                    return {
                        left: p.left - this.outerWidth() + t.outerWidth(),
                        top: p.top - this.outerHeight(),
                    };
                }

                return { left: p.left, top: p.top - this.outerHeight() };
            },
            bottom: function (t, s) {
                const self = Foundation.libs.dropdown,
                    p = self.dirs._base.call(this, t);

                if (
                    t.outerWidth() < this.outerWidth() ||
                    self.small() ||
                    this.hasClass(s.mega_menu)
                ) {
                    self.adjust_pip(this, t, s, p);
                }

                if (self.rtl) {
                    return {
                        left: p.left - this.outerWidth() + t.outerWidth(),
                        top: p.top + t.outerHeight(),
                    };
                }

                return { left: p.left, top: p.top + t.outerHeight() };
            },
            left: function (t, s) {
                const p = Foundation.libs.dropdown.dirs._base.call(this, t);

                this.addClass("drop-left");

                return { left: p.left - this.outerWidth(), top: p.top };
            },
            right: function (t, s) {
                const p = Foundation.libs.dropdown.dirs._base.call(this, t);

                this.addClass("drop-right");

                return { left: p.left + t.outerWidth(), top: p.top };
            },
        },

        // Insert rule to style psuedo elements
        adjust_pip: function (dropdown, target, settings, position) {
            let sheet = Foundation.stylesheet,
                pip_offset_base = 8;

            if (dropdown.hasClass(settings.mega_class)) {
                pip_offset_base = position.left + target.outerWidth() / 2 - 8;
            } else if (this.small()) {
                pip_offset_base += position.left - 8;
            }

            this.rule_idx = sheet.cssRules.length;

            const is_right = dropdown.hasClass("right"),
                sel_before =
                    ".f-dropdown.open" + (is_right ? ".right" : "") + ":before",
                sel_after =
                    ".f-dropdown.open" + (is_right ? ".right" : "") + ":after",
                dir = is_right ? "right" : "left",
                opposite_dir = is_right ? "left" : "right",
                css_before =
                    dir +
                    ": " +
                    pip_offset_base +
                    "px;" +
                    opposite_dir +
                    ": auto;",
                css_after =
                    dir + ": " + (pip_offset_base - 1) + "px;" + opposite_dir;
            +": auto;";

            if (sheet.insertRule) {
                sheet.insertRule(
                    [sel_before, "{", css_before, "}"].join(" "),
                    this.rule_idx
                );
                sheet.insertRule(
                    [sel_after, "{", css_after, "}"].join(" "),
                    this.rule_idx + 1
                );
            } else {
                sheet.addRule(sel_before, css_before, this.rule_idx);
                sheet.addRule(sel_after, css_after, this.rule_idx + 1);
            }
        },

        // Remove old dropdown rule index
        clear_idx: function () {
            const sheet = Foundation.stylesheet;

            if (this.rule_idx) {
                sheet.deleteRule(this.rule_idx);
                sheet.deleteRule(this.rule_idx);
                delete this.rule_idx;
            }
        },

        small: function () {
            return (
                matchMedia(Foundation.media_queries.small).matches &&
                !matchMedia(Foundation.media_queries.medium).matches
            );
        },

        off: function () {
            this.S(this.scope).off(".fndtn.dropdown");
            this.S("html, body").off(".fndtn.dropdown");
            this.S(window).off(".fndtn.dropdown");
            this.S("[data-dropdown-content]").off(".fndtn.dropdown");
        },

        reflow: function () {},
    };
})(jQuery, window, window.document);
