import { once } from "lodash";
import $ from "jquery";
import UserModel from "src/labs/users/UserModel";
import Session from "Session";

const bootstrap = once(() => {
    const userModel = new UserModel({
        user_id: Session.getInstance().getUserId(),
    });
    const initChatbot = function () {
        window.__be = window.__be || {};
        window.__be.id = "6620fe5b97077a0007c35b02";
        $.when(userModel.fetch()).done(() => {
            (function () {
                const be = document.createElement("script");
                be.type = "text/javascript";
                be.async = true;
                be.src =
                    ("https:" == document.location.protocol
                        ? "https://"
                        : "http://") + "cdn.chatbot.com/widget/plugin.js";

                const userName = `${userModel?.get(
                    "first_name"
                )} ${userModel?.get("last_name")}`;
                window.BE_API = window.BE_API || {};
                window.BE_API.onLoad = () => {
                    window.BE_API.setUserAttributes({
                        default_email: Session.getInstance().getEmail() || "",
                        default_name: userName || "",
                        account: Session.getInstance().getClientName() || "",
                        client_id: Session.getInstance().getClientId() || "",
                    });
                };

                const s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(be, s);
            })();
        });
    };
    if (!window.__be) {
        initChatbot();
    }

    Session.getInstance().onChange(() => {
        window.BE_API.resetSession();
    });
});

const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
};

export const initChatBotCloseButton = () => {
    const chatBotWrapper = document.querySelector("#chatbot-chat");
    if (!chatBotWrapper) {
        return;
    }
    const chatBotCloseButton = document.createElement("i");
    chatBotCloseButton.className = "fas fa-times chat-bot-close-button";
    chatBotWrapper.appendChild(chatBotCloseButton);
    chatBotCloseButton.addEventListener("click", () => {
        if (window.BE_API) {
            window.BE_API.destroy();
        }
    });
    window.BE_API.onChatWindowOpen = () => {
        chatBotCloseButton.style.display = "none";
    };

    window.BE_API.onChatWindowClose = () => {
        chatBotCloseButton.style.display = "none";
    };
    const handleMouseOver = () => {
        chatBotCloseButton.style.display = "block";
    };

    const handleMouseOut = () => {
        chatBotCloseButton.style.display = "none";
    };
    chatBotWrapper.addEventListener(
        "mouseover",
        debounce(handleMouseOver, 200)
    );
    chatBotWrapper.addEventListener("mouseout", debounce(handleMouseOut, 200));
};

export default {
    bootstrap,
};
