import Backbone from "backbone";
import _ from "underscore";

const UserModel = Backbone.Model.extend({
    idAttribute: "user_id",
    urlRoot: "/api/v2/users",
    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(
                this.attributes,
                "email",
                "password",
                "first_name",
                "last_name"
            );
        }
        return Backbone.sync.call(this, method, model, options);
    },
});

export default UserModel;
