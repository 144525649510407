class Client {
    constructor(clientResponse) {
        Object.assign(this, clientResponse);
    }
    getCoreVersion() {
        var _a;
        return (_a = this.core_settings) === null || _a === void 0 ? void 0 : _a.core_version;
    }
}
export default Client;
