import _ from "underscore";
import $ from "jquery";
import Uri from "jsuri";
import environment from "environment";

const MY_EXTOLE_API_PREFIX_PATTERN = /^\/api/;
const API_PROTOCOL_PATTERN = /^api:\/\//;
const LABS_PROTOCOL_PATTERN = /^labs:\/\//;
const ZONE_HOSTS = {
    pr: ["api-0.extole.com", "api-1.extole.com"],
    lo: ["api-0.lo.extole.com", "api-1.lo.extole.com"],
};

const labsHostName = (function () {
    const env = environment.getEnvironment();
    if (env === "pr") {
        return "labs.extole.com";
    }
    return `labs.${env}.extole.com`;
})();

const apiAvailabilityZoneHosts = (function () {
    const env = environment.getEnvironment();
    return ZONE_HOSTS[env] || ZONE_HOSTS.lo;
})();

function withCredentialsPrefilter(options) {
    options.xhrFields = _.extend(
        {
            withCredentials: true,
        },
        options.xhrFields || {}
    );
}

function setApiHost(uri) {
    uri.setHost(window.location.hostname);
}

function apiPrefilter(options, originalOptions, jqXHR, host) {
    if (
        options.url.match(MY_EXTOLE_API_PREFIX_PATTERN) ||
        options.url.match(API_PROTOCOL_PATTERN)
    ) {
        let path = options.url;
        path = path.replace(API_PROTOCOL_PATTERN, "/api");
        const uri = new Uri(path);
        uri.setProtocol("https:");
        setApiHost(uri);
        options.url = uri.toString();
        options.crossDomain = true;
        options.xhr.withCredentials = true;
        jqXHR.setRequestHeader("Accept", "application/json");
        if (localStorage.extoleAccessToken) {
            jqXHR.setRequestHeader(
                "Authorization",
                localStorage.extoleAccessToken
            );
        }
    }
}

function labsPrefilter(options, originalOptions, jqXHR) {
    if (options.url.match(LABS_PROTOCOL_PATTERN)) {
        let path = options.url;
        path = path.replace(LABS_PROTOCOL_PATTERN, "");
        const uri = new Uri(path);
        uri.setProtocol("https:");
        uri.setHost(labsHostName);
        options.url = uri.toString();
        options.crossDomain = true;
        options.xhr.withCredentials = true;
        jqXHR.setRequestHeader("Accept", "application/json");
        if (localStorage.extoleAccessToken) {
            jqXHR.setRequestHeader(
                "Authorization",
                localStorage.extoleAccessToken
            );
        }
    }
}

function eventFlowPrefilter(options, originalOptions, jqXHR) {
    jqXHR.setRequestHeader("X-Extole-App", "my_extole");
}

function AjaxPrefilter(statusHandlers, preFilters) {
    function filter(options, originalOptions, jqXHR, host) {
        _.each(preFilters, (preFilter) => {
            preFilter(options, originalOptions, jqXHR, host);
        });
    }

    $.ajaxPrefilter(filter);

    $.ajaxSetup({
        statusCode: statusHandlers,
    });

    function jqXhrNoop() {
        return $.ajax({
            type: "POST",
            url: "/noop",
            beforeSend() {
                return false;
            },
        });
    }

    this.processUrl = function (url, host) {
        const options = {
            url,
            xhr: {},
        };
        filter(options, options, jqXhrNoop(), host);
        return options.url;
    };

    this.processApiZones = function (url) {
        const self = this;
        return _.map(apiAvailabilityZoneHosts, (host) =>
            self.processUrl(url, host)
        );
    };
}

AjaxPrefilter.Builder = function () {
    this._statusHandlers = {};
    this._preFilters = [];

    this.withStatusHandlers = function (handlers) {
        _.extend(this._statusHandlers, handlers);
        return this;
    };

    this.withPreFilter = function (filter) {
        this._preFilters.push(filter);
        return this;
    };

    this.build = function () {
        return new AjaxPrefilter(this._statusHandlers, this._preFilters);
    };
};

export default {
    start: (onLogout = () => {}) =>
        new AjaxPrefilter.Builder()
            .withStatusHandlers({
                401() {
                    onLogout();
                },
            })
            .withPreFilter(withCredentialsPrefilter)
            .withPreFilter(apiPrefilter)
            .withPreFilter(labsPrefilter)
            .withPreFilter(eventFlowPrefilter)
            .build(),
};
