import _ from "underscore";
import Backbone from "backbone";
import $ from "jquery";
import componentHTML from "src/shared/dashboard-2.0/dashboard-test-mode-decoration.html?raw";

const EVENTS = {
    EXIT_TEST_MODE: "EXIT_TEST_MODE",
};
const DashboardTestModeActionsView = Backbone.View.extend(
    {
        template: _.template(componentHTML),

        events: {
            "click #js-dashboard-exit-test-mode": "exitTestMode",
        },

        render() {
            this.$el.html(this.template());
            $(document.body).prepend(this.$el);
            return this;
        },

        exitTestMode(event) {
            event.preventDefault();
            this.trigger(EVENTS.EXIT_TEST_MODE);
        },
    },
    {
        EVENTS,
    }
);

DashboardTestModeActionsView.create = function () {
    return new DashboardTestModeActionsView().render();
};

export default DashboardTestModeActionsView;
