import $ from "jquery";
const PATH = "api:///v2/me";
const get = () => $.ajax({
    method: "GET",
    url: `${PATH}`,
});
const listClients = () => $.ajax({
    method: "GET",
    url: `${PATH}/clients`,
});
export default {
    get,
    listClients,
};
