var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from "underscore";
import Session from "Session";
import MeEndpoints from "../api/me/MeEndpoints";
import Me from "../store/modules/me/model/Me";
import User from "../store/user/model/User";
import UserEndpoints from "../api/user/UserEndpoints";
import Client from "../store/modules/client/model/Client";
import ClientEndpoints from "../api/client/ClientEndpoints";
var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["EXTOLE"] = "EXTOLE";
    Role["TEST"] = "TEST";
    Role["USER_SUPPORT"] = "USER_SUPPORT";
})(Role || (Role = {}));
function fetchMe() {
    return new Promise((resolve) => {
        MeEndpoints.get().done((meResponse) => {
            resolve(new Me(meResponse));
        });
    });
}
function fetchClient(clientId) {
    return ClientEndpoints.getClient(clientId).then((clientResponse) => {
        return new Client(clientResponse.data);
    });
}
function fetchUser(userId) {
    return new Promise((resolve) => {
        UserEndpoints.get(userId).done((userResponse) => {
            resolve(new User(userResponse));
        });
    });
}
function fetchData() {
    return __awaiter(this, void 0, void 0, function* () {
        const me = yield fetchMe();
        const [myClient, myUser] = yield Promise.all([
            fetchClient(me.client_id),
            fetchUser(me.user_id),
        ]);
        return {
            me,
            myUser,
            myClient,
        };
    });
}
function initializePendo(pendo) {
    let role = Role.ADMIN;
    if (Session.getInstance().isExtoleUser()) {
        role = Role.EXTOLE;
    }
    if (Session.getInstance().isTestUser()) {
        role = Role.TEST;
    }
    if (Session.getInstance().isUserSupportUser()) {
        role = Role.USER_SUPPORT;
    }
    fetchData().then((data) => {
        const id = data.myUser.user_id;
        const { email } = data.me;
        const fullName = data.myUser.getFullName();
        const pendoData = {
            visitor: {
                id,
                email,
                role,
                full_name: fullName,
            },
            account: {
                id: data.myClient.client_id,
                name: data.myClient.short_name,
                client_type: data.myClient.client_type,
            },
        };
        pendo.initialize(pendoData);
    });
}
const bootstrap = _.memoize(function () {
    (function (apiKey) {
        (function (p, e, n, d) {
            let v, w, x, y, z;
            const o = (p[d] = p[d] || {});
            o._q = o._q || [];
            v = [
                "initialize",
                "identify",
                "updateOptions",
                "pageLoad",
                "track",
            ];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                            function () {
                                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                            };
                })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
        // Call this whenever information about your visitors becomes available
        // Please use Strings, Numbers, or Bools for value types.
        const { pendo } = window;
        initializePendo(pendo);
    })("bee2328f-7fd7-420b-6a67-2d4b348fbb7b");
});
export default {
    bootstrap,
};
