import axios from "axios";
const PATH = "api:///v4/clients";
const getClients = () => axios({
    method: "GET",
    url: `api:///v4/clients`,
});
const getClient = (id) => axios({
    method: "GET",
    url: `${PATH}/${id}`,
});
const createClient = (data) => axios({
    method: "POST",
    url: `${PATH}`,
    data: JSON.stringify(data),
    headers: {
        "Content-Type": "application/json",
    },
});
const editClient = (id, data) => axios({
    method: "PUT",
    url: `${PATH}/${id}`,
    data: JSON.stringify(data),
    headers: {
        "Content-Type": "application/json",
    },
});
const deleteClient = (id) => axios({
    method: "DELETE",
    url: `${PATH}/${id}`,
});
export default {
    getClients,
    getClient,
    editClient,
    deleteClient,
    createClient,
};
