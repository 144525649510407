/* eslint-disable */
import $ from "jquery";
import _ from "underscore";
import environment from "environment";

var bootstrap = _.once(function () {
    executeZoneSetupScript();
    var coreEnvironment = environment.getEnvironment() === "pr" ? "" : "." + environment.getEnvironment();
    $(document.body).append("<script id='jst-core' src='https://extole-invite" + coreEnvironment +
        ".extole.com/core.js'></script>");
});

function executeZoneSetupScript() {
    // eslint-disable-next-line
    (function(c,b,f,k,a){c[b]=c[b]||{};for(c[b].q=c[b].q||[];a<k.length;)f(k[a++],c[b])})(window,"extole",function (c,b){b[c]=b[c]||function (){b.q.push([c,arguments])}},["createZone", "log"],0);
}

function createClientInviteZone(element, email) {
    bootstrap();
    executeZoneSetupScript();
    extole.createZone({
        name: "referral_page",
        element_id: "extole-invite-client",
        element: element,
        data: {
            email: email || "",
            getVerificationCode: getVerificationCode
        }
    });
}

function createRegisterZone(email, firstName, lastName, partnerUserId) {
    bootstrap();
    executeZoneSetupScript();
    extole.createZone({
        name: "register",
        data: {
            email: email,
            first_name: firstName,
            last_name: lastName,
            partner_user_id: partnerUserId
        }
    });
}

function createMarketingZone(element) {
    bootstrap();
    executeZoneSetupScript();
    extole.createZone({
        name: "login_marketing_zone",
        element: element
    });
}

function createGlobalNotificationZone(element) {
    bootstrap();
    executeZoneSetupScript();
    extole.createZone({
        name: "global_notification",
        element: element
    });
}

function getVerificationCode(callback) {
    $.ajax({
        url: "/api/v2/verification-code",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        success: function (response) {
            callback(response.verification_code);
        },
        error: function (error) {
            callback();
        }
    });
}

export default {
    bootstrap: bootstrap,
    createClientInviteZone: createClientInviteZone,
    createRegisterZone: createRegisterZone,
    createMarketingZone: createMarketingZone,
    createGlobalNotificationZone: createGlobalNotificationZone
};

