import Session from "js/Session";
class User {
    constructor(userResponse) {
        Object.assign(this, userResponse);
    }
    getFullName() {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name}`;
        }
        if (this.first_name) {
            return this.first_name;
        }
        if (this.last_name) {
            return this.last_name;
        }
        return null;
    }
    isMe() {
        return Session.getInstance().getUserId() === this.user_id;
    }
    getDisplayName() {
        return `${this.getFullName() || ""}${this.isMe() ? " (Me)" : ""} ${this.email}`.trim();
    }
    isExtoleUser() {
        var _a;
        return Boolean((_a = this.email) === null || _a === void 0 ? void 0 : _a.match(/extole\.com$/));
    }
    isUserSupportUser() {
        return this.scopes.length === 1 && this.scopes.includes("USER_SUPPORT");
    }
}
export default User;
