import axios from "axios";
import Uri from "jsuri";
import _ from "underscore";
import environment from "environment";

const MY_EXTOLE_API_PREFIX_PATTERN = /^\/api/;
const API_PROTOCOL_PATTERN = /^api:\/\//;
const LABS_PROTOCOL_PATTERN = /^labs:\/\//;
const ZONE_HOSTS = {
    pr: ["api-0.extole.com", "api-1.extole.com"],
    lo: ["api-0.lo.extole.com", "api-1.lo.extole.com"],
};

const labsHostName = (function () {
    const env = environment.getEnvironment();
    if (env === "pr") {
        return "labs.extole.com";
    }
    return `labs.${env}.extole.com`;
})();

const apiAvailabilityZoneHosts = (function () {
    const env = environment.getEnvironment();
    return ZONE_HOSTS[env] || ZONE_HOSTS.lo;
})();

function setApiHost(uri) {
    uri.setHost(window.location.hostname);
}

function apiPrefilter(config) {
    if (
        config.url?.match(MY_EXTOLE_API_PREFIX_PATTERN) ||
        config.url?.match(API_PROTOCOL_PATTERN)
    ) {
        let path = config.url;
        if (path) {
            path = path.replace(API_PROTOCOL_PATTERN, "/api");
            const uri = new Uri(path);
            uri.setProtocol("https:");
            setApiHost(uri);
            config.url = uri.toString();
        }
        config.headers = {
            ...config.headers,
            Accept: "application/json",
        };
        if (localStorage.extoleAccessToken) {
            config.headers["Authorization"] = localStorage.extoleAccessToken;
        }
    }
    return config;
}

function labsPrefilter(config) {
    if (config.url?.match(LABS_PROTOCOL_PATTERN)) {
        let path = config.url;
        if (path) {
            path = path.replace(LABS_PROTOCOL_PATTERN, "");
            const uri = new Uri(path);
            uri.setProtocol("https:");
            uri.setHost(labsHostName);
            config.url = uri.toString();
        }
        config.headers = {
            ...config.headers,
            Accept: "application/json",
        };
        if (localStorage.extoleAccessToken) {
            config.headers["Authorization"] = localStorage.extoleAccessToken;
        }
    }
    return config;
}

function eventFlowPrefilter(config) {
    config.headers = {
        ...config.headers,
        "X-Extole-App": "my_extole",
    };
    return config;
}

function withCredentialsPrefilter(config) {
    config.withCredentials = true;
    return config;
}

function AxiosPrefilter(statusHandlers, preFilters) {
    axios.interceptors.request.use((config) => {
        preFilters.forEach((preFilter) => {
            config = preFilter(config);
        });
        return config;
    });

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const { response } = error;
            if (response && statusHandlers[response.status]) {
                statusHandlers[response.status]();
            }
            return Promise.reject(error);
        }
    );

    this.setupStatusHandlers = function () {
        this.axiosInstance.defaults.validateStatus = (status) => {
            if (statusHandlers[status]) {
                statusHandlers[status]();
            }
            return status >= 200 && status < 300;
        };
    };

    this.processUrl = function (url, host) {
        let config = {
            url,
            host,
        };
        preFilters.forEach((preFilter) => {
            config = preFilter(config);
        });
        return config.url;
    };

    this.processApiZones = function (url) {
        return _.map(apiAvailabilityZoneHosts, (host) =>
            this.processUrl(url, host)
        );
    };
}

AxiosPrefilter.Builder = function () {
    this._statusHandlers = {};
    this._preFilters = [];

    this.withStatusHandlers = function (handlers) {
        _.extend(this._statusHandlers, handlers);
        return this;
    };

    this.withPreFilter = function (filter) {
        this._preFilters.push(filter);
        return this;
    };

    this.build = function () {
        return new AxiosPrefilter(this._statusHandlers, this._preFilters);
    };
};

export default {
    start: (onLogout = () => {}) =>
        new AxiosPrefilter.Builder()
            .withStatusHandlers({ 401: onLogout })
            .withPreFilter(withCredentialsPrefilter)
            .withPreFilter(apiPrefilter)
            .withPreFilter(labsPrefilter)
            .withPreFilter(eventFlowPrefilter)
            .build(),
};
