let environment;
try {
    environment = ENVIRONMENT;

    if (!environment) {
        environment = "pr";
    }
} catch (e) {
    environment = "pr";
}

const getEnvironment = () => environment;
const isProduction = () => environment === "pr";
export default {
    getEnvironment,
    isProduction,
};
