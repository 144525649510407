import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import template from "src/shared/tooltip/tooltip-template.html?raw";
import Foundation from "foundation";

const TOOLTIP_SELECTOR = ".js-tooltip";

const TooltipView = Backbone.View.extend({
    template: _.template(template),

    initialize(parameters) {
        this.triggerType = parameters.triggerType;
        this.message = parameters.message;
        this.trigger = parameters.trigger;
        this.position = parameters.position;
    },

    render() {
        const triggerText = this.$el.text();

        this.$el.html(
            this.template({
                triggerType: this.triggerType,
                message: this.message,
                position: this.position,
            })
        );
        const $trigger = this.$(".js-tooltip-trigger");
        if (this.trigger) {
            $trigger.html(this.trigger);
        } else {
            $trigger.text(triggerText);
        }

        Foundation.init(this.$el.parent(), { tooltip: {} });
        return this;
    },

    clear() {
        const tooltipSelector = this.$el
            .find("[data-selector]")
            .data("selector");
        $(`#${tooltipSelector}`).remove();
        this.$el.empty();
        this.el.tooltipRendered = false;
    },
});

TooltipView.renderAll = function () {
    let $tooltips;
    let configurations;
    const args = Array.prototype.slice.call(arguments);
    if (args.length === 2 && args[0] instanceof $) {
        $tooltips = args[0].find(TOOLTIP_SELECTOR);
        configurations = args[1];
    } else if (args.length === 1) {
        $tooltips = $(TOOLTIP_SELECTOR);
        configurations = args[0];
    } else {
        return [];
    }

    const views = [];
    $tooltips.each((index, element) => {
        if (element.tooltipRendered) {
            return;
        }
        const $tooltip = $(element);
        const tooltipId = $tooltip.data("tooltip-id");
        const configuration = _.findWhere(configurations, {
            id: tooltipId,
        });
        if (configuration && !_.isEmpty(configuration.message)) {
            views.push(
                new TooltipView(
                    _.extend(configuration, {
                        el: $tooltip,
                    })
                ).render()
            );
            element.tooltipRendered = true;
        }
    });

    return views;
};

export default TooltipView;
